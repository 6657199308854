// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidebar {
  padding: 20px;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#sidebar::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mEAAA;EACA,aAAA;EACA,kBAAA;EAKA,wBAAA;EACA,qBAAA;AAHF;AAFE;EACE,aAAA;AAIJ","sourcesContent":["#sidebar {\n  padding: 20px;\n  background-color: #0093e9;\n  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);\n  height: 100vh;\n  overflow-y: scroll;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
