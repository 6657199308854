import React, { useContext, useEffect, useState } from "react";
import "./Sidebar.scss";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { AppContext } from "../../providers/AppProvider";
import { AddIcon } from "@chakra-ui/icons";
import PartNumber from "../partnumber/PartNumber";
export default function Sidebar() {
  const {
    data,
    setData,
    tailleMoule,
    setTailleMoule,
    taillePartMoule,
    setTaillePartMoule,
  } = useContext(AppContext);
  const [sizes, setSizes] = useState([]);

  const addSize = () => {
    setSizes([...sizes, Date.now()]);
  };

  const removeSize = (index) => {
    const newSizes = [...sizes];
    const indexToRemove = newSizes.indexOf(index);

    let cpData = { ...data };
    if (cpData.hasOwnProperty(index)) {
      delete cpData[index];
    }
    setData(cpData);

    if (indexToRemove !== -1) {
      newSizes.splice(indexToRemove, 1);
    }
    setSizes(newSizes);
  };

  return (
    <aside id="sidebar">
      <VStack gap={10}>
        <FormControl>
          <FormLabel>Taille du moule</FormLabel>
          <Input
            type="number"
            value={tailleMoule}
            onChange={(e) => setTailleMoule(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Taille de la part</FormLabel>
          <Input
            type="number"
            value={taillePartMoule}
            onChange={(e) => setTaillePartMoule(e.target.value)}
          />
        </FormControl>
        <AddIcon
          _hover={{ cursor: "pointer" }}
          onClick={() => {
            addSize();
          }}
          boxSize={6}
        />
        <VStack gap={5}>
          {sizes.map((size) => (
            <PartNumber key={size} remove={removeSize} index={size} />
          ))}
        </VStack>
      </VStack>
    </aside>
  );
}
