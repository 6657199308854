import { createContext, useState } from "react";

export const AppContext = createContext();

export default function AppProvider({ children }) {
  const [tailleMoule, setTailleMoule] = useState(0);
  const [taillePartMoule, setTaillePartMoule] = useState(0);
  const [finalArray, setFinalArray] = useState([]);
  const [data, setData] = useState([]);

  const providerValue = {
    tailleMoule,
    setTailleMoule,
    taillePartMoule,
    setTaillePartMoule,
    data,
    setData,
    finalArray,
    setFinalArray,
  };

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}
