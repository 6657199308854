import React, { useContext } from "react";
import "./Navbar.scss";
import { Button } from "@chakra-ui/react";
import { AppContext } from "../../providers/AppProvider";
export default function Navbar() {
  const {
    data,
    setData,
    tailleMoule,
    setTailleMoule,
    taillePartMoule,
    setTaillePartMoule,
    finalArray,
    setFinalArray,
  } = useContext(AppContext);

  const calculate = () => {
    let cpData = { ...data };
    cpData = Object.values(cpData)
      .sort((a, b) => Number(b.nbPart) - Number(a.nbPart))
      .map((element) => {
        element.array = Array.from({ length: Number(element.qte) }, () =>
          Number(element.nbPart)
        );
        return element;
      });

    let totalArray = [];
    let again = true;
    //tant qu'il reste des place
    while (again) {
      let notTouched = true;
      let placeRestante = tailleMoule;
      let array = [];
      cpData.forEach((element) => {
        if (element.array.length > 0) {
          // si il reste des éléments dans mon tableau
          let currentNbPart = element.array[0];
          let totalPartSize = currentNbPart * taillePartMoule;
          placeRestante -= array
            .map((num) => num * taillePartMoule)
            .reduce((sum, multipliedNum) => sum + multipliedNum, 0);

          if (placeRestante >= currentNbPart * taillePartMoule) {
            let possible = Math.floor(placeRestante / totalPartSize);
            if (possible <= element.array.length) {
              for (let i = 0; i < possible; i++) {
                array.push(element.array.shift());
              }
            } else {
              let nb = element.array.length;
              for (let i = 0; i < nb; i++) {
                array.push(element.array.shift());
              }
            }
          }
          notTouched = false;
        }
      });

      if (array.length > 0) {
        totalArray.push(array);
      }

      if (notTouched) {
        again = false;
      }
    }

    setFinalArray(totalArray);
  };

  return (
    <header id="navbar">
      <Button onClick={() => calculate()} color={"blue.500"}>
        Calculer les parts
      </Button>
    </header>
  );
}
