// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#grid-template {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 80px auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  height: 100vh;
}

#sidebar {
  grid-area: 1/1/6/2;
}

#navbar {
  grid-area: 1/2/2/6;
}

#content {
  grid-area: 2/2/6/6;
}

.buche {
  background-color: antiquewhite;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/app.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,6BAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,8BAAA;AACF","sourcesContent":["#grid-template {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  grid-template-rows: 80px auto;\n  grid-column-gap: 0px;\n  grid-row-gap: 0px;\n  overflow: hidden;\n  height: 100vh;\n}\n\n#sidebar {\n  grid-area: 1 / 1 / 6 / 2;\n}\n\n#navbar {\n  grid-area: 1 / 2 / 2 / 6;\n}\n\n#content {\n  grid-area: 2 / 2 / 6 / 6;\n}\n\n.buche {\n  background-color: antiquewhite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
