import React, { useContext, useEffect, useState } from "react";
import "./PartNumber.scss";
import { CloseIcon } from "@chakra-ui/icons";
import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import { AppContext } from "../../providers/AppProvider";
export default function PartNumber(props) {
  const [nbPart, setNbPart] = useState(0);
  const [qte, setQte] = useState(0);

  const { data, setData } = useContext(AppContext);

  useEffect(() => {
    setData({
      ...data,
      [props.index]: {
        nbPart,
        qte,
      },
    });
  }, [nbPart, qte]);
  return (
    <VStack gap={2} position="relative">
      <CloseIcon
        position={"absolute"}
        zIndex={10}
        top={0}
        right={0}
        _hover={{ cursor: "pointer" }}
        onClick={() => {
          props.remove(props.index);
        }}
      />
      <FormControl>
        <FormLabel>Nombre de part</FormLabel>
        <Input
          type="number"
          value={nbPart}
          onChange={(e) => setNbPart(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Quantité</FormLabel>
        <Input
          type="number"
          value={qte}
          onChange={(e) => setQte(e.target.value)}
        />
      </FormControl>
    </VStack>
  );
}
