import React, { useContext, useEffect, useState } from "react";
import "./Content.scss";
import { AppContext } from "../../providers/AppProvider";
import { Box, Flex, HStack } from "@chakra-ui/react";
export default function Content() {
  const { finalArray } = useContext(AppContext);
  const [test, setTest] = useState([]);
  useEffect(() => {
    setTest(finalArray);
  }, [finalArray]);

  return (
    <main id="content">
      {finalArray.map((innerArray, outerIndex) => (
        <Flex alignItems={"center"} key={outerIndex}>
          <Box m={3}>Tournée n°{outerIndex + 1}</Box>
          {innerArray.map((number, innerIndex) => (
            <HStack
              key={innerIndex}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              m={3}
            >
              <Box
                mr={2}
                mt={5}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ width: number * 30 + "px", height: "40px" }}
                className={"buche"}
                key={innerIndex}
              >
                {number} parts
              </Box>
            </HStack>
          ))}
        </Flex>
      ))}
    </main>
  );
}
