import logo from "./logo.svg";
import "./App.css";
import "./assets/scss/app.scss";

import { ChakraProvider } from "@chakra-ui/react";
import Sidebar from "./components/sidebar/Sidebar";
import AppProvider from "./providers/AppProvider";
import Navbar from "./components/navbar/Navbar";
import Content from "./components/content/Content";
function App() {
  return (
    <ChakraProvider>
      <AppProvider>
        <div id="grid-template">
          <Sidebar />
          <Navbar />
          <Content />
        </div>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
